import React from "react";
import { scrollToSection, getPathname } from '../../helpers/globals';
import './styles/styles.scss';
import { Link } from 'gatsby';


export default function Footer() {
    let isContactPage = getPathname()?.includes('/kontakt');
    let isRodoPage = getPathname()?.includes('/rodo');

    return (
        <>
            <div className='footer position-relative'>
                <div className="line line-vertical-left"></div>
                <div className="line line-vertical-right"></div>
                <div className="line line-horizontal"></div>
                <div className='container footer__container position-relative'>
                    <div className="footer__top">
                        <div className="footer__top--left">
                            <h3 className="title">{isContactPage ? (
                                <>
                                    <h3 className='title'>Nawiążmy</h3>
                                    <h3 className='title blue'>współprace</h3>
                                </>
                            ) : 'Porozmawiajmy'}
                            </h3>
                        </div>
                        <div className="footer__top--right">
                            <ul className="xl-small">
                                {!isRodoPage && !isContactPage ?
                                    (
                                        <>
                                            <li><a href="#activity" onClick={() => scrollToSection('#activity')}>O nas</a></li>
                                            <li><a href="#pill" onClick={() => scrollToSection('#pill')}>DBD o pigułce</a></li>
                                            <li><a href="#operations" onClick={() => scrollToSection('#operations')}>Obszar działania</a></li>
                                            <li><a href="#cooperations" onClick={() => scrollToSection('#cooperations')}>Korzyści</a></li>
                                            <li><a href="#caseStudies" onClick={() => scrollToSection('#caseStudies')}>Case Studies</a></li>
                                        </>
                                    ) : null
                                }

                            </ul>
                            <div>
                                <div className="footer__center--right xl-small">
                                    <div>
                                        <p className="gray">Porozmawiajmy</p>
                                        <a href="tel:+48600727216">+48 600 72 72 16</a>
                                        <a href="mailto:biuro@digitalbuildingdata.com">biuro@digitalbuildingdata.com</a>
                                        <div className="footer__center--left button">
                                            <Link to='/kontakt'>Wyślij wiadomość</Link>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="gray">Biuro</p>
                                        <p>DIGITALBUILDINGDATA</p>
                                        <p>SPÓŁKA Z OO</p>
                                        <p>ul. Kalwaryjska 9/15</p>
                                        <p>30-504 KRAKÓW</p>
                                    </div>
                                </div>
                            </div>
                            <div className="footer__bottom">
                                <div className="footer__bottom--left">
                                    <span>
                                        Copyright 2022 Digital Building Data
                                    </span>
                                </div>
                                <div className="footer__bottom--right">
                                    <Link to='/rodo'>Polityka RODO</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nav-small">
                        <ul>
                            <li><a href="#activity" onClick={() => scrollToSection('#activity')}>O nas</a></li>
                            <li><a href="#pill" onClick={() => scrollToSection('#pill')}>DBD o pigułce</a></li>
                            <li><a href="#operations" onClick={() => scrollToSection('#operations')}>Obszar działania</a></li>
                            <li><a href="#cooperations" onClick={() => scrollToSection('#cooperations')}>Korzyści</a></li>
                            <li><a href="#caseStudies" onClick={() => scrollToSection('#caseStudies')}>Case Studies</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}