const isBrowser = typeof window !== "undefined"

export function scrollToSection(where) {
    const section = document.querySelector( where );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
};

export function getPathname() {
    if (!isBrowser) {
        return;
      }
    
      return window.location.pathname;
};