import React, { useState } from 'react';
import './styles/styles.scss';
import Footer from '../sections/footer/Footer';
import { Helmet } from "react-helmet";
import { Form, Button } from 'react-bootstrap';
import Image from '../components/image/Image';
import { Link } from 'gatsby';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [isSended, setIsSended] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = `name=${name}&email=${email}&phone=${phone}&message=${message}`;

        fetch('/form-contact/index.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: body
        }).then((response) => {
            if (response.ok) {
                setIsSended(true);

                setTimeout(() => {
                    setName('');
                    setEmail('');
                    setPhone('');
                    setMessage('');
                }, 2000);
            } else {
                setIsError(true);
            }
        });
    };

    return (
        <div>
            <Helmet title="Kontakt | Digital Building Data">
                <link href="https://api.fontshare.com/v2/css?f[]=satoshi@700,500,400&display=swap" rel="stylesheet" />
            </Helmet>
            <div className='contact'>
                <div className='contact__container container'>
                    <nav className='navigation navigation-white'>
                        <a href='/'>
                            <Image src='logo.svg'
                                className='mx-auto shadow-xl navigation-logo'
                                alt='logo' />
                        </a>
                        <div className='navigation--links'>
                            <Link className='button-language' to='https://digitalbuildingdata.com/contact'>
                                <Image src="world.svg" />
                                <span>
                                    EN
                                </span>
                            </Link>
                        </div>
                    </nav>
                    <div className='form__container'>
                        <h3 className='title white inline'>Napisz do nas </h3>
                        <h3 className='title blue inline'>wiadomość</h3>

                        <Form className='w-100 form' onSubmit={handleSubmit} id="contact">
                            <Form.Group className='form-group w-100 form-top'>
                                <Form.Control onChange={(e) => setName(e.target.value)} className="form-control" type="text" name="name" value={name} required autoComplete='off' placeholder="Imię i nazwisko" />
                                <Form.Control onChange={(e) => setEmail(e.target.value)} className="form-control" type="email" size="30" name="email" value={email} required autoComplete='off' placeholder='E-mail' />
                                <Form.Control onChange={(e) => setPhone(e.target.value)} type="tel" name="phone" value={phone} required autoComplete='off' placeholder="Telefon" />
                            </Form.Group>
                            <Form.Group className='form-group w-100 form-bottom'>
                                <Form.Control onChange={(e) => setMessage(e.target.value)} as="textarea" className="form-control" type="textarea" name="message" value={message} required autoComplete='off' placeholder="Twoja wiadomość" />
                            </Form.Group>
                            <Button className='saveButton hover' type="submit" form="contact">
                                Wyślij wiadomość
                            </Button>
                            {isSended &&
                                <div className='result'>
                                    -Wysłano. Dziękujemy za kontakt. 
                                </div>
                            }
                            {isError &&
                                <div className='result'>
                                    -Wystąpił błąd. Spróbuj ponownie.
                                </div>
                            }
                        </Form>
                    </div>
                </div>
            </div>
            <Footer engFooter='false' />
        </div>
    )
}

export default Contact;